import { ExceptionsState } from "../common/types"
import { getBooleanCookie, getCookie, getIntCookie, setCookie } from "../common/ts-utils"
import { ExceptionAction } from "../actions/exceptions"

const initialState: ExceptionsState = {
    filterExceptions: getCookie("filter_exceptions") === "true",
    cicoDiffException: {
        enabled: getCookie("cicoDiffException") === "true",
        value: getCookie("exception_threshold") || "",
        units: (getCookie("exception_units") as "hours" | "percent") || "hours",
    },
    hoursException: {
        enabled: getCookie("hoursException") === "true",
        minHours: getCookie("min_hours_exception_threshold") || "",
        maxHours: getCookie("max_hours_exception_threshold") || "",
    },
    shiftsBreaksException: {
        enabled: getCookie("shiftsBreaksException") === "true",
        // Cookies cannot store booleans, just strings. So we store "true" or "false" and convert it to boolean.
        // The default is also true if no cookie has been set.
        missingShift: getBooleanCookie("shift_break_exception_missing_shift", true),
        missingMeal: getBooleanCookie("shift_break_exception_missing_meal", true),
        missingBreak: getBooleanCookie("shift_break_exception_missing_break", true),
        minBreakDurationEnabled: getBooleanCookie("shift_break_exception_min_break_duration_enabled", true),
        minBreakDuration: getIntCookie("shift_break_exception_min_break_duration", 30),
        maxTimeBeforeMealEnabled: getBooleanCookie("shift_break_exception_max_time_before_meal_enabled", true),
        maxTimeBeforeMeal: getIntCookie("shift_break_exception_max_time_before_meal", 300),
    },
}

const exceptions = (state = initialState, action: ExceptionAction): ExceptionsState => {
    switch (action.type) {
        case "TOGGLE_EXCEPTION":
            setCookie(
                action.whichException,
                state[action.whichException].enabled ? "false" : "true",
                "/",
                undefined
            )
            return {
                ...state,
                [action.whichException]: {
                    ...state[action.whichException],
                    enabled: !state[action.whichException].enabled,
                },
            }
        case "SET_EXCEPTION_VALUE":
            return {
                ...state,
                ...action.exception,
            }
        case "TOGGLE_FILTER_EXCEPTIONS":
            return {
                ...state,
                filterExceptions: !state.filterExceptions,
            }
        default:
            return state
    }
}

export default exceptions
