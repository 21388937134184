import { Action, AnyAction, Store } from "redux"
import { ThunkAction, ThunkDispatch } from "redux-thunk"
import {
    iCompanyFormStore,
    tEntityState,
    tPaginationState,
    iTkModifier,
    iPicklist,
    iEmployeeWorkShift,
    iWorkShift,
    iTimekeepingStatus,
    iCostCode,
    iEquipment,
} from "../cached-data/types"
import { iMutableSourceDataState, tNetworkStatusState, tSourceData } from "../dashboard-data/types"
import { tFilterState, tStartOfWeekIndex } from "../filters/types"
import { getFlagEnabled, getFlagValue } from "../getFlagValue"
import { iCompanyFormSchema, tProject } from "../cached-data/types"
import { tGuestFormShares, tTransformForImportState } from "../reducers/types"
import { CompareStoreType } from "../forms/BulkReviewForm/types"
import { tCopyToModalState } from "../components/modals/types"
import { tSocketNotificationState } from "../websockets/reducers"
import { tContext } from "../components/custom-dashboards/types"
import { Column, RowNode } from "ag-grid-community"
import { ImportToFieldFormModalParamsType } from "../forms/FieldFormsForm/types"
import { tWeatherState } from "../reducers/weather"
import { tSchemaVariantState } from "../reducers/schema-variants"

declare global {
    interface Window {
        capn_version: string
        Intercom: any
        intercom_api_key: string
        ld_client_id: string
        rmbx: {
            getFlagEnabled: typeof getFlagEnabled
            getFlagValue: typeof getFlagValue
        }
        msCrypto: Crypto
        rmbx_env: string
        mapbox_public_key: string
    }
}

export interface iActionData extends Action {
    data: Record<string, any>
}

export type tJSONValue = null | string | number | boolean | iJSONObject | iJSONArray

export interface iJSONObject {
    [x: string]: tJSONValue
}

// API v4 errors are mapped to a field, e.g. /project or /modifier_active/non_field_errors
// with one or more errors attached to each field. We also use other keys (like `serverErrors`)
// to indicate row-level errors.
export type GridFieldErrors = {
    errors?: Record<string, string[]>
}

type iJSONArray = Array<tJSONValue>

interface iHttpResponse {
    headers: Array<string>
    ok: boolean
    redirected: boolean
    status: number
    statusText: string
    url: string
    body: string
    json: Promise<string>
    text: Promise<string>
    // TODO: subclass this as an error type to avoid having to coerce errors
    // then provide a property that tells if it's a 40x or a 50x and possibly logs the 500s automatically
    error?: string
    detail?: string
}

export type tHttpResponse = Readonly<iHttpResponse>

export type tHttpResponseFields = keyof tHttpResponse

export type tUrlForResource = {
    absences: string
    accountSettings: string
    analyticsDashboards: string
    apiIntegration: string
    autodeskToken: string
    changeOrders: string
    cohorts: string
    cohortEmployees: string
    companies: string
    companyAbsenceTypes: string
    companyAnalyticsDashboards: string
    companyCrewTypes: string
    companyFormSchemas: string
    companyFormSchemasForDashboard: string
    companyFormStores: string
    companyGroups: string
    companyStartStopTypes: string
    costCodeControls: string
    costCodes: string
    costItems: string
    dailySummaryPreferences: string
    employeeEntries: string
    employees: string
    employeeClassifications: string
    employeeSchemas: string
    employeeSchemasForDashboard: string
    employeeTrades: string
    employeeLicenses: string
    employeeCertifications: string
    employeeUnions: string
    ewsStartStopTimes: string
    equipment: string
    groupMembers: string
    guestFormShares: string
    materials: string
    modifyProduction: string
    picklistItems: string
    projectEmployees: string
    projectEquipment: string
    projectMaterials: string
    projects: string
    projectShares: string
    quantities: string
    schemaStatusNames: string
    timeCards: string
    timekeepingEntries: string
    timekeepingModifiersForDashboard: string
    timekeepingStatuses: string
    timelineEntryVersions: string
    timelineEntries: string
    tkExportFormats: string
    workShifts: string
    companyTrades: string
    companyClassifications: string
    companyTextFieldOptions: string
}

export type tHttpVerb = "CONNECT" | "DELETE" | "GET" | "HEAD" | "OPTIONS" | "PATCH" | "POST" | "PUT" | "TRACE"

export type tResourceName = keyof tUrlForResource

export type tValueFormatter = { (args: any): string }

export interface iReferenceableValueFormatters {
    valueFormatter?: tValueFormatter
    prefixFormatter?: tValueFormatter
    titleFormatter?: tValueFormatter
    primarySubtitleFormatter?: tValueFormatter
    secondarySubtitleFormatter?: tValueFormatter
    tertiarySubtitleFormatter?: tValueFormatter
    titleAndStatusFormatter?: tValueFormatter
    cicoValueFormatter?: tValueFormatter
}

export type tReferenceablesToValueFormatters = { [key in tResourceName]?: iReferenceableValueFormatters }
export type tReferenceableInactiveChecks = { [key in tResourceName]?: (item: Record<string, any>) => boolean }

// auth state from API v3
export type tUserRole =
    | "WORKER"
    | "FOREMAN"
    | "ADMIN"
    | "PM"
    | "OFFICE_STAFF"
    | "PAYROLL_ADMIN"
    | "GUEST"
    | "GUEST_SIGNATURE"

export type tCompanyRole = "OWNER" | "GENERAL_CONTRACTOR" | "SUBCONTRACTOR"

export type tFeatureFlagKeys =
    | "admin.api_integration"
    | "admin.bim360"
    | "admin.mass_text"
    | "admin.quantity_edit"
    | "budget_cost_codes"
    | "cico"
    | "cmic"
    | "cost_code_controls"
    | "cost_code_level_modifiers"
    | "custom_dashboards_beta"
    | "custom_dashboards_demo"
    | "custom_form"
    | "daily_foreman_only_signature"
    | "daily_report"
    | "disable_mobile_shift_setup_cost_code_repopulate"
    | "duplicate_shift_extras"
    | "email_settings"
    | "equipment"
    | "force_new_field_names"
    | "foreman_access_all_project_forms"
    | "gc_company"
    | "gc_optional_workflow"
    | "pco_number_dashboard"
    | "group_restricted_data"
    | "groups"
    | "hide_notes"
    | "impersonate"
    | "large_project_count_optimizations"
    | "maps"
    | "materials_requisition"
    | "materials"
    | "mobile_production"
    | "password_timeout"
    | "payroll_approvals_no_ap_ex"
    | "pdf_printing"
    | "pricing"
    | "read_only_cost_codes"
    | "read_only_employees"
    | "report.payroll"
    | "shift_extras"
    | "shifts_and_breaks"
    | "signature_webview_mobile"
    | "sso_login"
    | "surespan_verification"
    | "text_for_signature"
    | "timekeeping"
    | "tm_invite_campaign"
    | "tm_pilot_workflow"
    | "web_production"
    | "hide_daily_view"
    | "show_attached_pdfs_inline"
    | "enterprise_sso_configuration"
    | "disable_web_signature_flow"

export type tFeatures = { [key in tFeatureFlagKeys]: boolean }

export type tCompany = {
    id: number
    start_of_week: string
    start_of_week_index: tStartOfWeekIndex // calculated by get_current_user()
    company_name: string
    registration_date: string
    company_description: string | null
    company_website: string | null
    csm: string | null
    is_active: boolean
    various_info: iJSONObject | null
    address: string | null
    role: {
        name: string
        value: string
    }
}

export type tCurrentUserEmployee = {
    company: tCompany //api v3 returns whole company object
    allowed_tk_statuses?: string[]
    id: number
    email: string
    phone: string | null
    company_supplied_id: string
    first_name: string
    last_name: string
    classification: string
    trade: string
    user_role: tUserRole
    fullsize: string | null
    thumbnail: string | null
    user_id: number
    employee_id: number
    is_active: boolean
}

export type SignatureOptions = {
    require_signature?: boolean
    signature_period?: SignaturePeriod
    signature_roles?: string // TODO remove this
    signature_pad_type?: SignaturePadType
    prompt_eod_questions?: boolean
    display_start_stop?: boolean
}

export type tCompanyProjectOptions = SignatureOptions & {
    enable_pricing?: boolean
    employee_pricing_type?: string
    equipment_pricing?: boolean
    material_pricing?: boolean
}

export interface iCurrentUser {
    id: number
    // This is using the API v3 serializer, not the API v4 serializer we use
    // for referenceables
    employee: tCurrentUserEmployee
    employee_id: number
    user_id: number
    first_name: string
    last_name: string
    user_role: tUserRole
    email: string
    phone: string // We serialize None to "None" here ¯\_(ツ)_/¯
    fullsize: string | null
    thumbnail: string | null
    company_supplied_id: string
    date_joined: string
    company: string
    company_id: number
    company_role: string
    company_options: tCompanyProjectOptions & {
        id: number
        lock_end_date: string | null
        timekeeping_increment?: number
    }
    list_views: Array<any>
    features: tFeatures
    is_staff: boolean
    company_tk_modifiers?: Array<iTkModifier>
    employee_attribute_picklists?: Array<iPicklist>
    employee_start_of_week?: DayOfWeek
    text_field_options: Array<tCompanyTextFieldOption>
}

export type tTransformOption = {
    created_on: string
    from_name: string
    from_schemas: number[]
    id: number
    name: string
    single_item_transform: boolean
    template: string
    to_name: string
    to_schema: number
    is_bundle?: boolean
}

export type tCustomFormTransform = {
    transform_id: number
    project_id: number
    schema_id: number
    store_ids: number[]
}

export type tFileAttachment = {
    name?: string
    type?: string
    url: string
}

export type tSchemaPermission = {
    company_role: "GC" | "SUB" | "GUEST"
    schema_id: number
    user_role: tUserRole
    status: number
    object_permissions: any
    field_permissions: any
}

export type GuestUser = {
    id: number
    email: string
    company_name: string
    job_title: string
    first_name: string
    last_name: string
}

export type GuestFormShare = {
    id: number
    permissions: "REQUEST_SIGNATURE" | "VIEW_ONLY"
    guest: GuestUser
    last_updated: string
    form_history_id: number
    view_count: number
    access_expires_on: string
    created_on: string
    form: number
    created_by: number
}

export type ModalCustomFormGuestSave = {
    open: boolean
    currentStatus: string
    nextStatuses: string[]
    storeId: string
    validatedData: any
    errors: string[]
    guestEmail: string
    signedKey: string
    guestLastName: string
    guestExpiration: number
}

export type ModalGuestInfo = {
    guestEmail: string
    open: boolean
    signedKey: string
}

export type ModalGuestInfoError = {
    open: boolean
    error: string
}

export type ModalCreateSavedFilter = {
    open: boolean
    filterState: tFilterState
    page: string
    message: string
}

export type ModalDeleteSavedFilter = {
    open: boolean
    id: number
    page: string
    message: string
}

export type ModalAddRowsToWeeklyTKModal = {
    open: boolean
    resource: tResourceName
    resourceDisplayName: string
    context: tContext
    colDefs: Column[]
}

export type ModalAddCohortEmployeesModal = {
    open: boolean
    cohortId: number
    context: tContext
    colDefs: Column[]
}

export type ModalAddPicklistItem = {
    open: boolean
    context: tContext
    itemName: string
    picklistId: string
    picklistName: string
    costCodeId: number | iCostCode
    row: RowNode
}

export type ModalWeeklyAddWorkShift = {
    open: boolean
    context: tContext
}

export type ModalAddEditWorkShift = ModalWeeklyAddWorkShift & {
    workShift: iWorkShift | number
    sourceData: tSourceData
    row: RowNode
}

export type ModalImportToFieldForm = {
    importToFieldFormModalOpen: boolean
    importToFieldFormModalParams?: ImportToFieldFormModalParamsType
}

export type ModalLeaveFieldFormState = {
    isLeaveFieldFormModalOpen: boolean
}

export type ModalSSOConfigurationConfirmation = {
    open: boolean
    errorMessage: string
}

export type modalTKSignTimecard = {
    open: boolean
}

export type ModalsState = {
    modalAddRowsToWeeklyTKModal: ModalAddRowsToWeeklyTKModal
    modalAddCohortEmployeesModal: ModalAddCohortEmployeesModal
    modalAddEditWorkShift: ModalAddEditWorkShift
    modalAddPicklistItem: ModalAddPicklistItem
    modalCustomFormGuestSave: ModalCustomFormGuestSave
    modalGuestInfo: ModalGuestInfo
    modalGuestInfoError: ModalGuestInfoError
    modalCopyTo: tCopyToModalState
    modalCreateSavedFilter: ModalCreateSavedFilter
    modalDeleteSavedFilter: ModalDeleteSavedFilter
    modalImportToFieldForm: ModalImportToFieldForm
    modalSSOConfigurationConfirmation: ModalSSOConfigurationConfirmation
    modalTKSignTimecard: modalTKSignTimecard
    modalWeeklyAddWorkShift: ModalWeeklyAddWorkShift
    modalLeaveFieldForm: ModalLeaveFieldFormState
}

export type FancySearchTerm = {
    term: string[] | string
    category: string
    doFilter: boolean
}

export type SearchBarState = {
    searchTerm: string
    fancySearchTerm: FancySearchTerm
    cicoVisible: boolean
    extraTimelineVisible: boolean
    isFancySearchVisible: boolean
    nonExceptionRowsVisible: boolean
    placeholdersVisible: boolean
    // The following can be removed with WA-8728-ca-compliance-exception
    cicoExceptions: boolean
    cicoExceptionValue: { value: string; units: "hours" | "percent" }
    hoursExceptions: boolean
    hoursExceptionValues: { minHours: string; maxHours: string }
    shiftsBreaksExceptions: boolean
    shiftsBreaksExceptionValues: { missingShift: boolean; missingMeal: boolean; missingBreak: boolean }
}

export type CicoDiffExceptionSettings = {
    enabled?: boolean // can remove the '?' with WA-8728-ca-compliance-exception
    value: string
    units: "hours" | "percent"
}

export type CicoDiffException = {
    cicoDiffException: CicoDiffExceptionSettings
}

export type HoursExceptionSettings = { enabled?: boolean; minHours: string; maxHours: string }

export type HoursException = {
    hoursException: HoursExceptionSettings
}

export type ShiftsBreaksExceptionSettings = {
    enabled?: boolean // can remove the '?' with WA-8728-ca-compliance-exception
    missingShift: boolean
    missingMeal: boolean
    missingBreak: boolean
    minBreakDurationEnabled: boolean
    minBreakDuration: number
    maxTimeBeforeMealEnabled: boolean
    maxTimeBeforeMeal: number
}
export type ShiftsBreaksException = {
    shiftsBreaksException: ShiftsBreaksExceptionSettings
}

export type AnyException = CicoDiffException | HoursException | ShiftsBreaksException
export type AllExceptions = CicoDiffException & HoursException & ShiftsBreaksException
export type ExceptionsState = AllExceptions & {
    filterExceptions: boolean
}

export type tSelectedParty = {
    permissions: "VIEW_ONLY" | "REQUEST_SIGNATURE"
    email_address: string
}

export enum ApprovalStatus {
    NOT_APPROVED = "PENDING",
    PAYROLL_VERIFIED = "APPROVED",
    SUPERVISOR_APPROVED = "SUPERVISOR_APPROVED",
    EXPORTED = "EXPORTED",
    SYNCED = "SYNCED",
}

// Common fields between Work Components, Absences, and Shift Extra Stores
export type BaseTkEntry = {
    date: string
    created_on: string
    employee: number
    employee_work_shift_id: number
    foreman: number
    id: number
    project: number
    shift_end_time: string
    shift_start_time: string
    status: string | iTimekeepingStatus
    work_shift_id: number
    // Weekly TK annotation that shows first employee who updated the TK Entry to an "approved status"
    approver_id?: number
}

export type Absence = BaseTkEntry & {
    absence_type: number
    code: string
    type: string
}

export type EmployeeFormStore = BaseTkEntry & {
    ews_id: number
    schema: number
    store: iJSONObject
}

export type SignaturePeriod = "DAILY" | "WEEKLY" | "UNKNOWN"

export type SignaturePadType = "HYBRID" | "DRAW" | "TYPE"

export type SignatureSettings = {
    display_start_stop?: boolean
    prompt_eod_questions?: boolean
    require_signature: boolean
    signature_period: SignaturePeriod
    signature_pad_type?: SignaturePadType
}

export type EODQuestionResponses = {
    injured: boolean | null
    breaks: boolean | null
    stretch: boolean | null
}

export type TimekeepingEntry = BaseTkEntry &
    SignatureSettings &
    EODQuestionResponses & {
        adjusted_minutes_dt: number
        adjusted_minutes_ot: number
        adjusted_minutes_st: number
        comment: string | null
        cost_code: number
        modifier_active?: {
            [key: string]: number
        }
        signature: string
    }

export type EmployeeWorkShiftStartStopTime = Omit<BaseTkEntry, "project"> & {
    duration_minutes: number
    is_break: boolean
    start_stop_type: string
    company_start_stop_type: number
    start_time: string
    stop_time: string
}

export type tRequestTimekeepingSignatures = {
    signatureUrl: string
}

export const FETCH_COMPANY_SSO_CONFIGURATION_SUCCEEDED = "FETCH_COMPANY_SSO_CONFIGURATION_SUCCEEDED"
export const FETCH_COMPANY_SSO_CONFIGURATION_FAILED = "FETCH_COMPANY_SSO_CONFIGURATION_FAILED"
export const CREATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED = "CREATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED"
export const CREATE_COMPANY_SSO_CONFIGURATION_FAILED = "CREATE_COMPANY_SSO_CONFIGURATION_FAILED"
export const UPDATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED = "UPDATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED"
export const UPDATE_COMPANY_SSO_CONFIGURATION_FAILED = "UPDATE_COMPANY_SSO_CONFIGURATION_FAILED"

export interface iFetchCompanySSOConfigurationSucceeded extends Action {
    type: typeof FETCH_COMPANY_SSO_CONFIGURATION_SUCCEEDED
    data: any
}

export interface iFetchCompanySSOConfigurationFailed extends Action {
    type: typeof FETCH_COMPANY_SSO_CONFIGURATION_FAILED
}

export interface iCreateCompanySSOConfigurationSucceeded extends Action {
    type: typeof CREATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED
}

export interface iCreateCompanySSOConfigurationFailed extends Action {
    type: typeof CREATE_COMPANY_SSO_CONFIGURATION_FAILED
}

export interface iUpdateCompanySSOConfigurationSucceeded extends Action {
    type: typeof UPDATE_COMPANY_SSO_CONFIGURATION_SUCCEEDED
}

export interface iUpdateCompanySSOConfigurationFailed extends Action {
    type: typeof UPDATE_COMPANY_SSO_CONFIGURATION_FAILED
}

export type tCompanyTextFieldOption = {
    model: string
    name: string
    label: string
    read_only_in_app: boolean
    included_in_details: boolean
}

export type tCompanySSOConfigurationActions =
    | iFetchCompanySSOConfigurationSucceeded
    | iFetchCompanySSOConfigurationFailed
    | iCreateCompanySSOConfigurationSucceeded
    | iCreateCompanySSOConfigurationFailed
    | iUpdateCompanySSOConfigurationSucceeded
    | iUpdateCompanySSOConfigurationFailed

// TODO: Fill this in with slices of the state as we go.
export type ReduxState = {
    activeSavedFilter: any // TODO: Type this
    auth: {
        email: string
        errorMessage: string
        initialized: boolean
        isLoading: boolean
        isLoginCheckPassed: boolean
        isOidcSsoEnabled: boolean
        // can be removed with RN-1757-cico-worker-experience
        unverifiedUserRole: string
        token: string | null
        guestEmail?: string
        guestFirstName?: string
        guestLastName?: string
        onAssignedExceptionsList: false
        guestPermissions?: Record<string, any>
        guestCompanyLogoUrl?: string
        guestExpiration?: number
        guestFormId?: number
    }
    company: {
        logo: string
    }
    companyFormSchema: {
        all: iCompanyFormSchema[]
        single: iCompanyFormSchema
    }
    companyFormStore: {
        all: iCompanyFormStore[]
        compare: CompareStoreType
    }
    customForm: {
        customFormData: iCompanyFormStore
        project: tProject
        downloadUrl?: string
        maxHistory: iCompanyFormStore | null
        editingActive?: boolean
    }
    companySSOConfiguration: Record<string, any>[]
    current_user: iCurrentUser
    employeeWorkShifts: iEmployeeWorkShift[]
    entities: tEntityState
    exceptions: ExceptionsState
    featureFlags: {
        features: Record<string, boolean>
    }
    filters: tFilterState
    groupBy: Record<string, string>
    guestFormShares: tGuestFormShares
    modals: ModalsState
    networkStatus: tNetworkStatusState
    pagination: tPaginationState
    pendingFilters: tFilterState
    projectEquipment: { equipment: iEquipment[] }
    requestTimekeepingSignatures: tRequestTimekeepingSignatures
    savedFilterSets: Record<string, any>[]
    schemaPermissions: { schemaPermissions: Array<tSchemaPermission> }
    schemaVariants: tSchemaVariantState
    searchBar: SearchBarState
    socketNotification: tSocketNotificationState
    sourceData: iMutableSourceDataState
    transformsForImport: tTransformForImportState
    weather: tWeatherState
}

export type AppDispatch = ThunkDispatch<ReduxState, null, AnyAction>
export type AppStore = Store<ReduxState, AnyAction> & { dispatch: AppDispatch }

/**
 * These Thunk types aren't perfect, but hopefully they help out in the short-term.
 * We can eventually update the type of Action<any> if we type out all of our Redux actions.
 * Haven't yet figured out how to have TS complain if the Thunk type is used for an async function.
 *
 * Specify a return type if your thunk returns a value.
 * Use AsyncThunk with a return type if your dispatched thunk returns a Promise.
 *
 * Usage:
 *
 *     const foo = (arg1, ...): Thunk => (dispatch [, getState]) => { ... }
 *     dispatch(foo(arg1, arg2))
 *
 *     const bar = (): Thunk<string> => (dispatch [, getState]) => { return "whoda thunk?" }
 *     const ret = dispatch(bar())
 *
 *     const baz = (): AsyncThunk<number> => async (dispatch [, getState]) => { return 42 }
 *     const ret = await dispatch(baz())
 *     // or, alternatively:
 *     dispatch(baz()).then((ret) => { ... })
 */
export type Thunk<T = void> = ThunkAction<T, ReduxState, null, AnyAction>
export type AsyncThunk<T = void> = ThunkAction<Promise<T>, ReduxState, null, AnyAction>

export type MixedToggleState = "on" | "off" | "mixed"

export type DayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY"

export type CicoEventType = "CLOCK_IN" | "CLOCK_OUT" | "START_BREAK" | "END_BREAK" | "START_MEAL" | "END_MEAL"
// | "CHANGE_TASK"

export type MetadataCapture = "REQUIRED" | "CAPTURED" | "DISABLED"
export type DistanceUnit = "MI" | "KM"
export type GeofenceDrawMode = "polygon" | "radius"

export type Device = {
    brand: string
    device_id: string
    name: string
    os_name: string
    os_version: string
}
