import { iEmployee } from "../../../cached-data/types"
import { ResourceObject } from "../../../dashboard-data/types"
import { getFlagEnabled } from "../../../getFlagValue"
import {
    CicoDiffExceptionSettings,
    EmployeeWorkShiftStartStopTime,
    HoursExceptionSettings,
    ShiftsBreaksExceptionSettings,
} from "../../../common/types"
import { tContext } from "../types"
import { Dispatch } from "react"

/**
 * Activate an exception filter
 * @param toggleFn
 */
export const activateException = (toggleFn: () => Record<string, any>) => {
    return (context: tContext) => {
        const dateCols = context.columnApi!.getAllGridColumns()
        context.gridApi!.setFilterModel({
            [dateCols.at(-1)!.getId()]: {},
        })
        return (dispatch: Dispatch<any>) => dispatch(toggleFn())
    }
}

/**
 * Determine whether the data from a cell constitutes an exception for the CI/CO time
 * @param data Cell data
 * @param exceptionValue The exception value and units
 */
const hoursExceedCico = (
    data: { cicoTime: number; clocked_in_duration: number },
    exceptionValue: CicoDiffExceptionSettings
) => {
    const enteredHours = data.cicoTime
    const cicoHours = (data.clocked_in_duration || 0) / 60
    if (!enteredHours) return false
    const value = parseFloat(exceptionValue.value)
    if (exceptionValue.units === "hours") return Math.abs(enteredHours - cicoHours) > value
    else {
        if (!cicoHours || !enteredHours) return true
        if (enteredHours > cicoHours) return Math.abs(1 - cicoHours / enteredHours) > value / 100
        else return Math.abs(1 - enteredHours / cicoHours) > value / 100
    }
}

/**
 * Determine whether a cell constitutes a CI/CO exception
 * @param exceptionValue The value and units of the exception
 * @param cellData The data for the cell
 * @param employee Optional employee object. There are different circumstances depending on where
 * this function gets called. If we have the employee, it's a little simpler as the totals have
 * already been stored there
 */
export const isCicoException = (
    exceptionValue: CicoDiffExceptionSettings,
    cellData?: {
        ST: number
        DT: number
        OT: number
        cicoTime: number
        clocked_in_duration: number
        byDate: { string: { cicoTime: number; clocked_in_duration: number } }
    },
    employee?: iEmployee
) => {
    if (!employee && cellData) {
        if (getFlagEnabled("WA-8708-cico-improvements")) {
            if (!cellData.byDate) {
                return hoursExceedCico(cellData, exceptionValue)
            }
            return Object.values(cellData.byDate).some(data => {
                return hoursExceedCico(data, exceptionValue)
            })
        } else {
            const enteredHours = (cellData.ST || 0) + (cellData.OT || 0) + (cellData.DT || 0)
            const cicoHours = (cellData.clocked_in_duration || 0) / 60
            const value = parseFloat(exceptionValue.value)
            if (exceptionValue.units === "hours") return Math.abs(enteredHours - cicoHours) > value
            else {
                if (!cicoHours || !enteredHours) return true
                if (enteredHours > cicoHours) return Math.abs(1 - cicoHours / enteredHours) > value / 100
                else return Math.abs(1 - enteredHours / cicoHours) > value / 100
            }
        }
    } else if (employee) {
        const { entered_time_duration, working_duration } = employee
        const value = parseFloat(exceptionValue.value)
        if (exceptionValue.units === "hours" && Math.abs(employee.cicoDiff || 0) > value * 60) return true
        else if (exceptionValue.units === "percent" && entered_time_duration && working_duration) {
            if (
                entered_time_duration > working_duration &&
                Math.abs(1 - working_duration / entered_time_duration) > value / 100
            )
                return true
            else if (working_duration && Math.abs(1 - entered_time_duration / working_duration) > value / 100)
                return true
        }
    }
}

export const isHoursExceptionOld = (
    exceptionValue: HoursExceptionSettings,
    cellData: Record<string, any>,
    employee?: iEmployee
) => {
    const enteredHours = employee
        ? (employee.entered_time_duration || 0) / 60
        : cellData.ST || 0 + cellData.OT || 0 + cellData.DT || 0
    return (
        !!enteredHours &&
        (enteredHours < parseFloat(exceptionValue.minHours) || enteredHours > parseFloat(exceptionValue.maxHours))
    )
}

/**
 * Determine whether this cell represents an exception for the amount of hours configured
 * @param exceptionValue Min/max hours that we check against
 * @param cellData The cell/row data
 * @param employee Optional employee. If we have this, we're not in a pivot cell and the computation is
 * a little different.
 */
export const isHoursException = (
    exceptionValue: HoursExceptionSettings,
    cellData: ResourceObject<any> & {
        ST?: number
        DT?: number
        OT?: number
        byDate?: { string: { ST: number; DT: number; OT: number } }
        noDataFound?: boolean
    },
    employee?: iEmployee
) => {
    // If we have an employee, we're just looking at a single cell
    if (employee) {
        const enteredHours = ((employee.entered_time_duration || 0) + (employee.localTimeDiff || 0)) / 60
        return (
            enteredHours < parseFloat(exceptionValue.minHours) || enteredHours > parseFloat(exceptionValue.maxHours)
        )
    }

    if ((typeof cellData === "object" && !Object.keys(cellData).length) || typeof cellData !== "object")
        return false

    // If we're split out by date, we're looking at an entire row so we check if every date fits within the
    // hour limits. If not it's an exception
    if (cellData.byDate) {
        return !Object.values(cellData.byDate as { string: { ST: number; DT: number; OT: number } }).every(
            (data: { ST: number; DT: number; OT: number }) => {
                const enteredHours = (data.ST || 0) + (data.OT || 0) + (data.DT || 0)
                return (
                    enteredHours >= parseFloat(exceptionValue.minHours) &&
                    enteredHours <= parseFloat(exceptionValue.maxHours)
                )
            }
        )
    } else {
        const enteredHours = (cellData.ST || 0) + (cellData.OT || 0) + (cellData.DT || 0)
        return (
            enteredHours < parseFloat(exceptionValue.minHours) || enteredHours > parseFloat(exceptionValue.maxHours)
        )
    }
}

/**
 * Check that a break exists in a set of start/stop times which is longer than the minimum
 * @param startStopTimes
 * @param minDuration
 */
export const anyBreakGreaterThanMinimum = (
    startStopTimes: EmployeeWorkShiftStartStopTime[],
    minDuration: number
): boolean => {
    return (
        !startStopTimes.find(sst => sst.start_stop_type === "Break") ||
        !!startStopTimes.find(sst => sst.start_stop_type === "Break" && sst.duration_minutes >= minDuration)
    )
}

/**
 * Check a set of shifts and breaks to find whether a meal occurs within a given number of minutes of the
 * shift start time.
 * @param startStopTimes List of shift start/stop times for an employee
 * @param numberOfMinutes Threshold in minutes
 */
export const mealWithinMinutesOfShiftStart = (
    startStopTimes: EmployeeWorkShiftStartStopTime[],
    numberOfMinutes: number
): boolean => {
    const shifts = startStopTimes.filter(sst => !sst.is_break)
    const meals = startStopTimes
        .sort((sst1, sst2) => (new Date(sst1.start_time) > new Date(sst2.start_time) ? -1 : 1))
        .filter(sst => sst.start_stop_type === "Meal")
    if (!shifts.length) return true
    return shifts.every(sst => {
        const shiftStartTime = new Date(sst.start_time)
        const shiftEndTime = new Date(sst.stop_time)
        if ((shiftEndTime.getTime() - shiftStartTime.getTime()) / 60000 < numberOfMinutes) return true
        // Find the first meal in the sorted list which is within the requested number of minutes
        return meals.find(m => {
            if (m.start_time < sst.start_time || m.start_time > sst.stop_time) return false
            const mealTime = new Date(m.start_time) as Date
            return (mealTime.getTime() - shiftStartTime.getTime()) / 60000 < numberOfMinutes
        })
    })
}

/**
 * Determine whether this cell represents an exception for any missing Shifts, Breaks, or Meals
 * @param exceptionValue Whether to check for missing Shifts, Breaks, and/or Meals
 * @param cellData The cell data
 */
export const isShiftsBreaksException = (
    exceptionValue: ShiftsBreaksExceptionSettings,
    cellData:
        | {
              startStopTypes: Set<string>
              startStopTimes: EmployeeWorkShiftStartStopTime[]
              byDate: {
                  string: { startStopTypes?: Set<string>; startStopTimes?: EmployeeWorkShiftStartStopTime[] }
              }
          }
        | "undefined"
) => {
    if (!cellData || cellData === "undefined" || typeof cellData !== "object" || Object.keys(cellData).length === 0)
        return false

    if (getFlagEnabled("WA-8728-ca-compliance-exception")) {
        let isException = false
        // Minimum break duration.
        if (exceptionValue.minBreakDurationEnabled) {
            // We make sure that every break in every day in the row is longer than the minimum duration
            if (cellData.byDate) {
                isException =
                    isException ||
                    !Object.values(cellData.byDate).every(
                        data =>
                            !data.startStopTimes?.length ||
                            anyBreakGreaterThanMinimum(data.startStopTimes, exceptionValue.minBreakDuration)
                    )
            } else {
                // Here we're just dealing with one day (the cell class rule) so make sure that one works.
                if (cellData.startStopTimes?.length)
                    isException =
                        isException ||
                        !anyBreakGreaterThanMinimum(cellData.startStopTimes, exceptionValue.minBreakDuration)
            }
        }
        // Make sure that the Meal has been taken within the specified number of minutes of the shift start
        if (exceptionValue.maxTimeBeforeMealEnabled) {
            if (cellData.byDate) {
                isException =
                    isException ||
                    !Object.values(cellData.byDate).every(
                        data =>
                            !data.startStopTimes?.length ||
                            mealWithinMinutesOfShiftStart(data.startStopTimes, exceptionValue.maxTimeBeforeMeal)
                    )
            } else {
                isException =
                    isException ||
                    (!!cellData.startStopTimes?.length &&
                        !mealWithinMinutesOfShiftStart(cellData.startStopTimes, exceptionValue.maxTimeBeforeMeal))
            }
        }
        if (cellData.byDate) {
            isException =
                isException ||
                (exceptionValue.missingShift &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Shift"))) ||
                (exceptionValue.missingBreak &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Break"))) ||
                (exceptionValue.missingMeal &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Meal")))
        } else {
            isException =
                isException ||
                (exceptionValue.missingShift && !cellData.startStopTypes?.has("Shift")) ||
                (exceptionValue.missingBreak && !cellData.startStopTypes?.has("Break")) ||
                (exceptionValue.missingMeal && !cellData.startStopTypes?.has("Meal"))
        }
        return isException
    } else {
        // When checking if the cell matches filters, we have to aggregate our own cell data, which
        // we do by date. Since we either filter the entire row or not at all, we can just check if
        // any of the dates in the row are an exception
        if (cellData.byDate)
            return (
                (exceptionValue.missingShift &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Shift"))) ||
                (exceptionValue.missingBreak &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Break"))) ||
                (exceptionValue.missingMeal &&
                    !Object.values(cellData.byDate).every(data => data.startStopTypes?.has("Meal")))
            )
        // When checking if we match a cellClassRule, we get the pre-aggregated data directly from AG-Grid
        // and it's only on a cell level, so we don't have to be clever about dates
        return (
            (exceptionValue.missingShift && !cellData.startStopTypes?.has("Shift")) ||
            (exceptionValue.missingBreak && !cellData.startStopTypes?.has("Break")) ||
            (exceptionValue.missingMeal && !cellData.startStopTypes?.has("Meal"))
        )
    }
}
