import { AllExceptions, AnyException, ExceptionsState } from "../common/types"

interface iToggleException {
    type: "TOGGLE_EXCEPTION"
    whichException: keyof AllExceptions
}

interface iSetExceptionValue {
    type: "SET_EXCEPTION_VALUE"
    exception: AnyException
}

interface iFilterExceptions {
    type: "TOGGLE_FILTER_EXCEPTIONS"
}

export const setExceptionValue = (exception: AnyException) => {
    return {
        type: "SET_EXCEPTION_VALUE",
        exception,
    }
}

export const toggleException = (whichException: keyof ExceptionsState) => {
    return {
        type: "TOGGLE_EXCEPTION",
        whichException,
    }
}

export const toggleFilterExceptions = () => {
    return {
        type: "TOGGLE_FILTER_EXCEPTIONS",
    }
}

export type ExceptionAction = iToggleException | iSetExceptionValue | iFilterExceptions
