import { combineReducers } from "redux"
import * as Sentry from "@sentry/react"
import rmbx from "../util"

import {
    aggridState,
    listViewSchemas,
    relatedTransforms,
    relatedSchemas,
    schemaPermissions,
    transformsForImport,
} from "./list-view"
import { cacheError, entities, entitiesLoading, pagination } from "../cached-data/reducers"
import { clipboard, groupBy, pivotClipboard } from "../toolbar/reducers"
import { company, logo } from "../logo-upload/reducers"
import { current_user, current_user_failed } from "./current_user"
import { filters, pendingFilters, savedFilterSets, initialFiltersApplied } from "../filters/reducers"
import { notification } from "../notifications/reducers"
import { socketNotification } from "../websockets/reducers"
import { sourceData, referenceableIds, networkStatus } from "../dashboard-data/reducers"
import absenceForm from "./absence-form"
import addCostCode from "./add-cost-code-form"
import analyticsDashboards from "./analytics_dashboards"
import apiIntegration from "./api-integration"
import auditHistory from "./audit-history"
import auth from "./auth"
import budget_items from "./budget-items"
import changeCostCode from "./change-cost-code-form"
import cohorts from "./cohorts"
import cohortEmployees from "./cohort-employees"
import companyAbsenceTypes from "./company-absence-types"
import companyFormSchema from "./company-form-schema"
import companyFormStore from "./company-form-store"
import companySSOConfiguration from "./company-sso-configurations"
import csv_data from "./csv_data"
import customForm from "./custom-form"
import customTooltipReducer from "./custom-tooltip-reducer"
import dailyTimeCardPage from "./daily_time_card_page"
import employee_id_filter from "./employee_id_filter"
import employees from "./employees"
import employeeWorkShifts from "./employee-work-shifts"
import exceptions from "./exceptions"
import exportCSV from "./export_csv"
import featureFlags from "./feature-flags"
import invitePage from "./invite_page"
import loadingIndicator from "./loading-indicator"
import lookerUrl from "./looker"
import massText from "./mass-text"
import materials from "./materials"
import modals from "../components/modals/reducers"
import popups from "./popups"
import project_form from "./project_form"
import projectEmployees from "./project_employees"
import projectEquipment from "./project_equipment"
import projectMarkups from "../markup/reducers"
import projectMaterials from "./project_materials"
import quantity from "./quantities"
import reports from "./reports"
import requestTimekeepingSignatures from "./timekeeping-signatures"
import rhumbixReferral from "./rhumbix-referral"
import schemaVariants from "./schema-variants"
import searchBar from "./search-bar"
import serverSideRowModel from "./server-side-row-model"
import sidebar from "./sidebar"
import timekeepingStatuses from "./timekeeping-statuses"
import weather from "./weather"
import workshiftForm from "./workshift-form"
import workShifts from "./work_shifts"
import { deleteCookie } from "../common/ts-utils"
import { getFlagEnabled } from "../getFlagValue"

const appReducer = combineReducers({
    absenceForm,
    addCostCode,
    aggridState,
    analyticsDashboards,
    apiIntegration,
    auditHistory,
    auth,
    budget_items,
    cacheError,
    changeCostCode,
    clipboard,
    cohorts,
    cohortEmployees,
    company,
    companyAbsenceTypes,
    companyFormSchema,
    companyFormStore,
    companySSOConfiguration,
    csv_data,
    current_user_failed,
    current_user,
    customForm,
    customTooltipReducer,
    dailyTimeCardPage,
    employee_id_filter,
    employees,
    employeeWorkShifts,
    entities,
    exceptions,
    exportCSV,
    featureFlags,
    filters,
    groupBy,
    initialFiltersApplied,
    invitePage,
    listViewSchemas,
    loadingIndicator,
    logo,
    lookerUrl,
    massText,
    materials,
    modals,
    networkStatus,
    notification,
    pagination,
    pendingFilters,
    pivotClipboard,
    popups,
    project_form,
    projectEmployees,
    projectEquipment,
    projectMarkups,
    projectMaterials,
    quantity,
    referenceableIds,
    referenceablesLoading: entitiesLoading,
    relatedSchemas,
    relatedTransforms,
    reports,
    requestTimekeepingSignatures,
    rhumbixReferral,
    savedFilterSets,
    schemaPermissions,
    schemaVariants,
    searchBar,
    serverSideRowModel,
    sidebar,
    socketNotification,
    sourceData,
    timekeepingStatuses,
    transformsForImport,
    weather,
    workshiftForm,
    workShifts,
})

// Reset to initial state when logging out.
const rootReducer = (state, action) => {
    if (action.type === "LOGOUT") {
        if (getFlagEnabled("WA-7694-cookies-for-auth")) {
            deleteCookie("token")
            deleteCookie("authorization_type")
        }
        rmbx.util.destroySession()
        state = undefined
        Sentry.configureScope(function (scope) {
            scope.setUser(null)
        })
    }
    return appReducer(state, action)
}

export default rootReducer
