import {
    colorFunctionalBlack,
    colorFunctionalGray10,
    colorFunctionalGray20,
    colorFunctionalGray30,
    colorFunctionalGray50,
    colorFunctionalGray60,
    colorFunctionalWhite,
    colorLayoutBorder,
    colorMiscGray90,
    fontSizeXs,
    IconButton,
    spacingM,
    spacingS,
    spacingSsm,
    spacingXl,
    spacingXs,
} from "@rhumbix/rmbx_design_system_web"
import styled from "styled-components"
import { eToolbarMode } from "../types"

import { getBackgroundColor, getTextColor } from "./utils"

export const Button = styled.button`
    box-sizing: border-box;
    background-color: inherit;
    border-color: transparent;
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    height: 36px;
    line-height: 1;
    min-width: 36px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;

    &:disabled {
        pointer-events: none;
    }

    &:focus,
    &:hover,
    &:hover:enabled {
        background-color: hsla(0, 0%, 100%, 0.5);
        opacity: 1;
    }
`

export const MenuContainer = styled.div`
    position: relative;
`

export const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-left: 5px;
`

export const Menu = styled.ul`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    list-style: none;
    margin: 0;
    min-width: 200px;
    padding: 0;
    position: absolute;
    top: 41px;
    left: -2px;
    z-index: 1000;
`

export const ChecklistWindow = styled.div`
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    margin: 0;
    min-width: 200px;
    padding: 0;
    position: absolute;
    top: 41px;
    left: -2px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
`

export const ExportWindow = styled(ChecklistWindow)<{ height?: number; minWidth?: number }>`
    ${props => (props.minWidth ? `min-width: ${props.minWidth}px;` : "min-width: 200px;")}
    ${props => (props.height ? `height: ${props.height}px;` : "height: auto")}
`

export const ChecklistButtonContainer = styled.div`
    display: flex;
    border-top: ${colorFunctionalGray30} solid 1px;
    padding: 8px;
    justify-content: space-between;
`

export const ExportButtonContainer = styled.div`
    display: flex;
    border-top: ${colorFunctionalGray30} solid 1px;
    padding: 8px;
    justify-content: right;
`

export const ExportButtonWindowButton = styled(IconButton)`
    display: flex;
    flex: 1;
    justify-content: center;
    max-width: 150px;
    &:not(:first-child) {
        margin-left: 4px;
    }
    &:not(:last-child) {
        margin-right: 4px;
    }
`

export const ChecklistSectionList = styled.ul`
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
    box-shadow: inset 0px -10px 10px -10px #00000045;
`

export const ChecklistSection = styled.li`
    display: block;
    padding-bottom: ${spacingXs};
`

export const ChecklistSectionLabel = styled.div`
    background-color: ${colorFunctionalGray20};
    display: block;
    font-size: ${fontSizeXs};
    font-weight: 500;
    padding: ${spacingM};
`

export const ChecklistSectionSearchContainer = styled.div`
    position: relative;

    > span {
        position: absolute;
        right: ${spacingM};
        top: calc(50% - ${spacingS});
        color: ${colorFunctionalGray60};
    }
`

export const ChecklistSectionSearchInput = styled.input`
    margin: ${spacingSsm};
    width: calc(100% - (2 * ${spacingSsm}));
    height: ${spacingXl};
    border: 1px ${colorFunctionalGray50} solid;
    padding: ${spacingXs} ${spacingM};
    border-radius: ${spacingXs};
    display: inline-block;
`

export const ChecklistList = styled.ul`
    list-style: none;
    padding-left: 0px;
    max-height: 300px;
    overflow-y: auto;
`

export const MenuChoice = styled.li`
    &:not(:last-child) {
        border-bottom: 1px solid ${colorLayoutBorder};
    }
    display: block;
    text-align: left;

    &:focus,
    &:hover {
        cursor: pointer;
        background-color: ${colorFunctionalGray10};
    }
`

export const MenuButton = styled.button`
    box-sizing: border-box;
    background-color: transparent;
    border-color: transparent;
    color: ${colorMiscGray90};
    display: block;
    font-size: 14px;
    font-weight: normal;
    height: 36px;
    line-height: 1;
    padding: 8px 16px;
    text-align: left;
    margin-top: 2px;
    vertical-align: middle;
    width: 100%;
    white-space: nowrap;

    svg path {
        fill: currentColor !important;
    }

    &:disabled {
        pointer-events: none;
    }

    &:focus,
    &:hover,
    &:hover:enabled {
        color: #212121;
        opacity: 1;
    }
`

export const MenuDescriptionButton = styled(MenuButton)`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: auto;
`
export const MenuButtonLabelText = styled.div`
    font-weight: 500;
`

export const MenuButtonDescriptionText = styled.div`
    font-size: ${fontSizeXs};
    margin-top: ${spacingXs};
`

export const MenuIconContainer = styled.div`
    margin-left: -4;
    margin-right: ${spacingS};
    vertical-align: middle;
    width: 20px;
`

export const Icon = styled.svg`
    display: inline-block;
    height: 20px;
    margin-right: 4px;
    transform: scale(0.8);
    vertical-align: middle;
    width: 20px;
`

export const SaveIndicatorContainer = styled.div`
    font-weight: 500;
    padding: 8px;
    position: absolute;
    right: 4px;
    top: 4px;
    display: flex;
`

export const SaveIndicatorImage = styled.img`
    margin-left: 4px;
    height: 20px;
    width: 20px;
`

export const SmallerIcon = styled(Icon)`
    height: 16px;
    width: 16px;
    margin-right: 8px;
`

// N.B. The height must be 46px exactly, or the entire page layout breaks with
// a bunch of vertical scrollbars. The scrolling also makes modals misalign
// with the browser's pixel grid and makes modal text blurry. We should, uh,
// fix that at some point.
export const ToolbarContainer = styled.div`
    background-color: ${getBackgroundColor};
    border-bottom: 1px solid #808080;
    color: ${getTextColor};
    display: flex;
    flex-direction: row;
    height: 46px;
    min-width: 100%;
    padding: 4px;
    position: relative;
    align-items: center;

    & button svg path {
        fill: "currentColor";
    }
`

export const ToolbarText = styled.p`
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 12px 8px;
`

const getSeparatorTextColor = (toolbarMode: eToolbarMode) => {
    switch (toolbarMode) {
        case eToolbarMode.CELL:
            return colorFunctionalBlack
        case eToolbarMode.ROW:
        case eToolbarMode.GROUP_ROW:
            return colorFunctionalWhite
        case eToolbarMode.TABLE:
        default:
            return colorFunctionalGray30
    }
}

export const ToolbarButtonSeparator = styled.div<{ toolbarMode: eToolbarMode }>`
    height: 32px;
    width: 1px;
    margin: ${spacingXs};
    border-left: 1px solid
        ${({ toolbarMode }) =>
            toolbarMode === eToolbarMode.ROW ? colorFunctionalBlack : getSeparatorTextColor(toolbarMode)};
    opacity: ${() => {
        return 0.3
    }};
`
export const PercentOrHoursSelector = styled.select`
    border: none;
    position: absolute;
    left: 400px;
    top: 138px;
    background-color: transparent;
`

export const ModalBody = styled.div`
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: baseline;
`

export const HoursLabel = styled.label`
    margin-right: 8px;
    margin-bottom: 5px;
`

export const CheckboxLabel = styled.label`
    margin-left: 8px;
    margin-bottom: 0px;
`

export const HoursInput = styled.input`
    height: 36px;
    width: 70px;
    text-align: right;
    padding-left: 8px;
    padding-right: 8px;
`
export const CheckboxDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 25px;
`
